import React, { useState } from 'react'

import SavorGoogleMap from '../components/SavorGoogleMap/SavorGoogleMap'
import ListOutlets from '../components/ListOutlets/ListOutlets'
import Layout from '../components/Layouts/default'
import { locations } from '../utils/locations'

export default function LocationPage() {
  const [state, setState] = useState({
    clickedLocationIndex: null,
    center: { lat: 21.0255094, lng: 105.8259268 },
    zoom: 14,
    locations: locations,
  })

  const clickTitleHandler = (location, index) => {
    setState({
      ...state,
      clickedLocationIndex: index,
      zoom: 16,
      center: { lat: location.lat, lng: location.lng },
    })
  }

  const openHandler = (location, index) => {
    setState({
      ...state,
      clickedLocationIndex: index,
      zoom: 16,
      center: { lat: location.lat, lng: location.lng },
    })
  }

  const closeHandler = (location) => {
    setState({
      ...state,
      clickedLocationIndex: null,
      zoom: 14,
      center: { lat: location.lat, lng: location.lng },
    })
  }

  return (
    <Layout>
      <div id="google-map">
        <SavorGoogleMap
          isMarkerShown
          locations={state.locations}
          zoom={state.zoom}
          center={state.center}
          clickedLocationIndex={state.clickedLocationIndex}
          onOpen={openHandler}
          onClose={closeHandler}
        />
        <ListOutlets
          onClickTitle={clickTitleHandler}
          locations={state.locations}
        />
      </div>
    </Layout>
  )
}
