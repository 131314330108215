import React from 'react'
import { Container, Segment, Grid, Header, Divider } from 'semantic-ui-react'
import * as classes from './ListOutlets.module.css'

export default function ListOutlets(props) {
  return (
    <Container>
      <Divider />
      <Header as="h1" textAlign="center">
        Danh sách các cơ sở
      </Header>
      <Grid columns={3}>
        {props.locations.map((location, index) => (
          <Grid.Column key={index}>
            <Segment>
              <Header as="h3">
                <a
                  className={classes.Title}
                  href="#google-map"
                  onClick={() => props.onClickTitle(location, index)}
                >
                  {location.title}
                </a>
              </Header>
              <p>
                <strong>Giờ mở cửa</strong>: {location.openingHours}
              </p>
              <p>
                <strong>Điện thoại</strong>: {location.phone}
              </p>
              <p>
                <strong>Địa chỉ</strong>: {location.address}
              </p>
            </Segment>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  )
}
