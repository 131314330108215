import React from 'react'
import { Header } from 'semantic-ui-react'
import { compose, withProps } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps'

const SavorGoogleMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDgyyXNArw67lAtAEObM-CQH-08ARYNkQE&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <div>
    <GoogleMap zoom={props.zoom} center={props.center}>
      {props.locations.map((location, index) => (
        <Marker
          key={index}
          position={{
            lat: location.lat,
            lng: location.lng,
          }}
          onClick={() => props.onOpen(location, index)}
        >
          {props.clickedLocationIndex === index && (
            <InfoWindow onCloseClick={() => props.onClose(location)}>
              <div>
                <Header as="h4">{location.title}</Header>
                <strong>Địa chỉ: </strong>
                {location.address}
                <br />
                <strong>Điện thoại: </strong>
                {location.phone}
                <br />
                <strong>Giờ mở cửa: </strong>
                {location.openingHours}
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  </div>
))

export default SavorGoogleMap
